import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import style from "./InputClear.module.scss";
import searchIcon from "../../../assets/search.png";

export default function InputClear({ id, placeholder, value, onKeyDown }) {
  const [innerValue, setInnerValue] = useState(value);

  const handleClick = () => {
    const searchBox = document.getElementById(id);
    if (searchBox) {
      setInnerValue(searchBox.value);
      onKeyDown(null, searchBox.value);
    }
  };

  const handleChange = (event) => {
    setInnerValue(event.target.value);
  };

  return (
    <div>
      <InputGroup>
        <FormControl
          id={id}
          placeholder={placeholder}
          className={style.input}
          type="text"
          onChange={handleChange}
          onKeyDown={onKeyDown}
          value={value}
        />
        <button className={style.searchButton} onClick={handleClick}>
          <img src={searchIcon}></img>
        </button>
      </InputGroup>
    </div>
  );
}
