import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import Job from "../job/Job";
import { CareersContext } from "../../CareersContext";
import noJobsImg from "../../../assets/no-jobs.svg";
import ReactPaginate from "react-paginate";

import "./JobList.css";

export default function JobList({pageOffset, handlePageClick}) {
  const { t } = useTranslation();
  const jobsContext = useContext(CareersContext);

  const endOffset = pageOffset * 10 + 10;

  const currentItems = jobsContext.jobs.slice(pageOffset * 10, endOffset);
  const pageCount = Math.ceil(jobsContext.jobs.length / 10);

  const handleClick = (event)=>{
    handlePageClick(event.selected)
  }

  return (
    <div>
      {jobsContext.jobs.length ? (
        <div>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handleClick}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={pageOffset}
        ></ReactPaginate>
          {currentItems.map((job) => (
            <Job
              id={job.id}
              title={job.title}
              jobDescription={job.abstract}
              locations={job.locations}
              isRemote={job.isRemote}
              isFulltime={job.isFulltime}
              skills={job.skills}
              seniority={job.seniority}
              workTypes={job.workTypes}
            ></Job>
          ))}
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handleClick}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={pageOffset}
          ></ReactPaginate>
        </div>
      ) : (
        <div className="text-center mt-3">
          <img src={noJobsImg}></img>
          <div>
            <p className="fw-bold">{t("noMatchTitle")}</p>
            <p>{t("noMatchSuggestion")}</p>
          </div>
        </div>
      )}
    </div>
  );
}
