import { createBrowserRouter } from "react-router-dom";
import JobApplication from "./components/jobs/job-application/JobApplication";
import JobDetails from "./components/jobs/job-details/JobDetails";
import Careers from "./components/careers/Careers";
import Nps from "./components/nps/Nps";
import GdprConsent from "./components/privacy/GdprConsent";
import JobsXml from "./components/syndicate/JobsXml";
import JobAlertSubscribe from "./components/job-alert/job-alert-subscribe/JobAlertSubscribe";
import JobAlertUnsubscribe from "./components/job-alert/job-alert-unsubscribe/JobAlertUnsubscribe";
import JobAlertResubscribe from "./components/job-alert/job-alert-resubscribe/JobAlertResubscribe";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Careers></Careers>,
  },
  {
    path: "/jobs",
    element: <Careers></Careers>,
  },
  {
    path: "/jobs/:jobId",
    element: <JobDetails></JobDetails>,
  },
  {
    path: "/jobs/:jobId?/apply",
    element: <JobApplication></JobApplication>,
  },
  {
    path: "/nps/:npsId",
    element: <Nps></Nps>,
  },
  {
    path: "/privacy/:gdprId",
    element: <GdprConsent></GdprConsent>,
  },
  {
    path: "/feed.xml",
    element: <JobsXml></JobsXml>,
  },
  {
    path: "/job-alert/subscribe",
    element: <JobAlertSubscribe></JobAlertSubscribe>,
  },
  {
    path: "/job-alert/unsubscribe/:token",
    element: <JobAlertUnsubscribe></JobAlertUnsubscribe>
  },
  {
    path: "/job-alert/resubscribe/:token",
    element: <JobAlertResubscribe></JobAlertResubscribe>
  }
]);

export default routes;
