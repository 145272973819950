import React from "react";

import style from "./Loader.module.css";
import spinner from "../../assets/loading.gif";

export default function Loader({ loading, children }) {
  return (
    <div className="text-center">
      {loading ? <img className={style.loader} src={spinner}></img> : null}
      <div className={loading ? style.container : null}>{children}</div>
    </div>
  );
}
