import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";

import styles from "./Job.module.scss";
import remoteJob from "../../../assets/remotejob.png";
import officeJob from "../../../assets/remotejob.png";
import fullTimeJob from "../../../assets/jobtype.png";
import partTimeJob from "../../../assets/jobtype.png";
import jobLevel from "../../../assets/joblevel.png";
import locationIcon from "../../../assets/location.png";
import linkedinIcon from "../../../assets/linkedin.svg";

export default function Job({
  title,
  jobDescription,
  isRemote,
  isFulltime,
  id,
  skills,
  seniority,
  locations,
  linkedinLink,
  workTypes,
}) {
  const { t } = useTranslation();
  const typeIcon = isRemote ? remoteJob : officeJob;
  const fullTimeIcon = isFulltime ? fullTimeJob : partTimeJob;

  const Location = () => {
    if(Object.keys(locations).length !== 1)
      return "International";
    else {
      if(locations[Object.keys(locations)[0]].length === 1){
        return locations[Object.keys(locations)[0]][0];
      }
      else return Object.keys(locations)[0];
    }
  }

  return (
    <Container>
      <Row>
        <Col md={10}>
          <div className={styles.container}>
            <div>
              <div>
                <Link className="nohover" to={`/jobs/${id}`}>
                  <h3 className={styles.title}>{title}</h3>
                </Link>
                <div>
                  {seniority ? (
                    <span className={styles.feature}>
                      <div className="d-inline-block">
                        <img src={jobLevel}></img>
                        {t(seniority)}
                      </div>
                    </span>
                  ) : null}
                  <span className={styles.feature}>
                    <div className="d-inline-block">
                      <img src={fullTimeIcon}></img>
                      {t(isFulltime ? "fullTime" : "partTime")}
                    </div>
                  </span>
                  {locations && Object.keys(locations).length > 0 ? (
                    <span className={styles.feature}>
                      <div className="d-inline-block">
                        <img src={locationIcon}></img>
                        {Location()}
                      </div>
                    </span>
                  ) : null}
                  <span className={styles.feature}>
                    <div className="d-inline-block">
                      <img src={typeIcon}></img>
                      {workTypes.map((w) => (
                        <span>{w}</span>
                      ))}
                    </div>
                  </span>
                  {linkedinLink ? (
                    <span className={styles.feature}>
                      <a
                        className="px-2 d-inline-block"
                        href={linkedinLink}
                        target="_blank" rel="noreferrer"
                      >
                        <img src={linkedinIcon}></img>
                      </a>
                    </span>
                  ) : null}
                </div>
              </div>
              <p className={styles.description}>{jobDescription}</p>
            </div>
            <div>
              {skills.map((skill) => (
                <span className={styles.skill}>{skill}</span>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
