import axios from "axios";

const loadConfig = async () => {
  const response = await fetch('/config.json');
  const config = await response.json();
  return config;
};

const createAxiosInstance = async () => {
  const config = await loadConfig();
  return axios.create({
    baseURL: config.baseURL,
    headers: {
      'x-api-version': '1.0'
    }
  });
};

export const getJobs = async (activeFilters) => {
  const axiosInstance = await createAxiosInstance();
  
  try{
    const res = await axiosInstance.post(`positions`, activeFilters);
    return res.data;
  } catch (err){
    throw new Error('Backend is down');
  };
  
};

export const getFilters = async () => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance
    .get(`positions/attributes`)
    .then((res) => res.data);
};

export const getJob = async (jobID) => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`positions/${jobID}`).then((res) => res.data);
};

export const applyJob = async (jobID, applicationDetails) => {
  const axiosInstance = await createAxiosInstance();

  if (jobID !== undefined)
    return axiosInstance
      .post(`positions/${jobID}/apply`, applicationDetails)
      .then((res) => res.data);
  else
    return axiosInstance
      .post("positions/apply", applicationDetails)
      .then((res) => res.data);
};

export const getNps = async (npsId) => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`nps/${npsId}`).then((res) => res.data);
};

export const submitNps = async (npsId, userNpsDetails) => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance
    .post(`nps/${npsId}/send`, userNpsDetails)
    .then((res) => res.data);
};

export const getGdprConsent = async (gdprId) => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`privacy/${gdprId}`).then((res) => res.data);
};

export const submitGdprConsent = async (gdprId) => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance
    .post(`privacy/${gdprId}/accept`)
    .then((res) => res.data);
};

export const getContractDurations = async () => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`positions/registers/ContractDuration`)
    .then((res) => res.data);
}

export const getRoles = async () => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`positions/registers/Role`)
    .then((res) => res.data);
}
export const getWorkTypes = async () => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`positions/registers/WorkType`)
    .then((res) => res.data);
}
export const getSeniorities = async () => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`positions/registers/Seniority`)
    .then((res) => res.data);
}
export const getLocations = async () => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`positions/registers/Locations`)
    .then((res) => res.data);
}

export const unsubscribe = async (token) => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`subscribers/${token}/unsubscribe`)
    .then((res) => res.data);
}

export const resubscribe = async (token) => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.get(`subscribers/${token}/subscribe`)
    .then((res) => res.data);
}

export const subscribe = async (subscriberDetails) => {
  const axiosInstance = await createAxiosInstance();

  return axiosInstance.post(`subscribers/subscribe`, subscriberDetails).then((res) => res.data);
}