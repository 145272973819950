import { RouterProvider } from "react-router-dom";

import "./App.scss";
import routes from "./routes";
import { useEffect, useState } from "react";
import Offline from "./components/offline/Offline";
import { getJobs } from "./api/sinapsi.services";

function App() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function CheckServer() {
      try {
        await getJobs({})
        setError(false);
        setLoading(false);
      }
      catch (err) {
        setError(true);
        setLoading(false);
      }
    }

    CheckServer();
  })

  if (loading) return <div>Loading...</div>;
  else if (error)
    return (<Offline />);
  else return (
    <div>
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
