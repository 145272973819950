import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import style from "./Footer.module.scss";
import logo from "../../assets/logo-white.png";
import { Container } from "react-bootstrap";

export default function Footer({ showJobs }) {
  const { t } = useTranslation();

  return (
    <div className={`${style.footer}`}>
      <Container className={`${style.wrap}`}>
        <Link className="px-2" to={`/`}>
          <span className="align-middle">{t("home")}</span>
        </Link>
        <Link className="px-2" to={`/jobs`}>
          <span className="align-middle">{t("jobs")}</span>
        </Link>
        <a
          className="px-2 nohover"
          href="https://www.rinf.tech/"
          target="_blank"
        >
          <img className={`${style.logo} float-right`} src={logo}></img>
        </a>
      </Container>
    </div>
  );
}
