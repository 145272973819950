import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import emailIcon from "../../../assets/email-icon.svg";
import { Link } from "react-router-dom";

import style from "./JobListMenu.module.css";

export default function JobListMenu() {
  const { t } = useTranslation();

  return (
    <div className={style.container}>
      <div className={style.filterContainer}>
        <div className={style.jobListMenuTitle}>
          {t("jobListMenuTitle")}
        </div>
        <div>
          {t("jobListMenuOpenApplication")}
        </div>
        <Link
            className="nohover d-inline-block me-3"
            to={`/jobs/apply`}
          >
            <button className={style.openApplicationButton}>
              {t("jobListMenuOpenApplicationButton")}
            </button>
        </Link>
        <div>
          {t("jobListMenuAlert")}
        </div>
        <Link
            className="nohover d-inline-block me-3"
            to={`/job-alert/subscribe`}
          >
            <button className={style.alertButton}>
              <img src={emailIcon}></img>
              {t("jobListMenuAlertButton")}
            </button>
        </Link>
      </div>
    </div>
  );
}