import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import styles from "./Navigator.module.scss";

export default function Navigator({ links }) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.container} mt-3 mb-4`}>
      {_.map(links, (item, index) =>
        index !== links.length - 1 ? (
          <span>
            <Link className={`${styles.link} nohover`} to={item.link}>
              <span>{t(item.label)}</span>
            </Link>
            <span className={`px-2 ${styles.splitter}`}>&#187;</span>
          </span>
        ) : (
          <span>{t(item.label)}</span>
        )
      )}
    </div>
  );
}
