import React, { useEffect, useState } from "react";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";

import Footer from "../footer/Footer";
import Loader from "../loader/Loader";
import Header from "../header/Header";
import JobList from "../jobs/job-list/JobList";
import { CareersContext } from "../CareersContext";
import InputClear from "../forms/input/InputClear";
import { getJobs } from "../../api/sinapsi.services";
import JobFilters from "../jobs/job-filters/JobFilters";
import JobListMenu from "../jobs/job-list-menu/JobListMenu";

function Careers() {
  const { t } = useTranslation();

  const [pageOffset, setPageOffset] = useState(0);

  const [jobs, setJobs] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    freeSearch: "",
    domain: [],
    deliveryCenter: [],
    workType: [],
    contractDuration: [],
    seniority: [],
    city: [],
    country: []
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPageOffset(0);
    loadJobs();
  }, []);

  useEffect(() => {
    setPageOffset(0);
    loadJobs();
  }, [activeFilters]);

  const loadJobs = () => {
    setLoading(true);
    getJobs(activeFilters).then((result) => {
      const jobs = result.map((original) => {
        const job = { ...original };
        job.isRemote = !_.includes(job.workTypes, "On-site", "Hybrid");
        job.isFulltime = job.contractDuration === "Full time";
        return job;
      });

      setJobs([...jobs]);
      setLoading(false);
    });
  };

  const handlePageClick = (value) => {
    setPageOffset(value);
  };

  const sideFiltersChanged = (filters) => {
    setPageOffset(0);
    const tempFilter = { ...activeFilters };

    if (filters.filter === 'locations')
    {
      tempFilter.city = _.map(_.filter(filters.selectedList, { type: 'Cities' }), 'name');
      tempFilter.country = _.map(_.filter(filters.selectedList, { type: 'Countries' }), 'name');
    }
    else
    {  
      if (filters.checked) {
        if (Array.isArray(tempFilter[filters.filter.key])) {
          tempFilter[filters.filter.key].push(filters.filter.name);
        } else {
          tempFilter[filters.filter.key] = filters.filter.name;
        }
      } else {
        if (Array.isArray(tempFilter[filters.filter.key])) {
          tempFilter[filters.filter.key] = _.filter(
            tempFilter[filters.filter.key],
            (item) => item !== filters.filter.name
          );
        } else {
          tempFilter[filters.filter.key] = "";
        }
      }
    }

    setActiveFilters(tempFilter);
  };

  const freeSearchFilterChanged = (box, value = null) => {
    setPageOffset(0);
    if (box && box.key === "Enter") {
      setActiveFilters({ ...activeFilters, freeSearch: box.target.value });
    } else if (value != null) {
      setActiveFilters({ ...activeFilters, freeSearch: value });
    }
  };

  return (
    <div>
      <CareersContext.Provider
        value={{
          filtersChanged: sideFiltersChanged,
          activeFilters: activeFilters,
          jobs: jobs,
        }}
      >
        <Header maximize={true} />
        <Container id="jobs-list" className="big-top-margin">
          <Row>
            <Col md={3}>
              <JobFilters filtersChanged={sideFiltersChanged}></JobFilters>
              <JobListMenu></JobListMenu>
            </Col>
            <Col className="px-0">
              <Loader loading={loading}>
                <Container>
                  <div className="mb-4">
                    <InputClear
                      id={"searchBox"}
                      placeholder={t("searchPlaceholder")}
                      onKeyDown={freeSearchFilterChanged}
                    ></InputClear>
                  </div>
                </Container>
                <JobList
                pageOffset={pageOffset}
                handlePageClick = {handlePageClick}
                ></JobList>
              </Loader>
            </Col>
          </Row>
        </Container>
      </CareersContext.Provider>
      <Footer></Footer>
    </div>
  );
}

export default Careers;
