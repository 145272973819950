import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { resubscribe } from "../../../api/sinapsi.services";

import { useParams } from "react-router-dom";
import { Container, Modal } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function JobAlertResubscribe() {
  const { t } = useTranslation();
  const { token } = useParams();
  const hasPushed = useRef(false);
  const [state, setState] = useState({
    submitting: false,
    submitted: false,
    showModal: false,
  });

  useEffect(() => {
    if (!hasPushed.current) {
      hasPushed.current = true;
      setState({ ...state, submitting: true });
      const result = resubscribe(token)
        .then(() => {
          setState({ ...state, submitting: false, submitted: true });
        })
        .catch((ex) => {
          setState({ ...state, showModal: true });
        });
      }
  }, []);

  function handleClose() {
    setState({ ...state, showModal: false });
  }

  if (state.submitted && !state.showModal) {
    return (
      <div className="text-center mt-4">
        <FontAwesomeIcon
          className="display-3"
          icon={faCheckCircle}
        ></FontAwesomeIcon>
        <h2 className="mt-3">Resubscribed to Alerts</h2>
        <p>You have successfully extended your subscription to job alerts.</p>
      </div>
    );
  }

  return (
    <div>
      <Container className="mt-4">
        <Modal show={state.showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Could not resubscribe</Modal.Title>
          </Modal.Header>
          <Modal.Body>There was an issue while resubscribing to job alerts. Please try again.</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}
