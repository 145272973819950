import React, { useEffect } from "react";
import { getJobs } from "../../api/sinapsi.services";


export default function JobsXml() {
    const downloadXML = (jobs) => {
        const convertToXML = (jobs) => {
            let xml = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>\n<jobs>\n';
            jobs.forEach(job => {
                xml += '  <job>\n';
                xml += `    <id>${job.id}</id>\n`;
                xml += `    <title>${job.title}</title>\n`;
                xml += `    <job_remote>${job.isRemote ? '1' : '0'}</job_remote>\n`;
                xml += `    <city>${job.city}</city>\n`;
                xml += `    <description>${job.abstract}</description>\n`;
                xml += `    <career_level>${seniorityConvert(job.seniority)}</career_level>\n`;
                xml += `    <job_type>${job.isFulltime ? '1' : '2'}</job_type>\n`;
                xml += `    <application_url>https://jobs.rinf.tech/jobs/${job.id}/apply</application_url>\n`;
                xml += '  </job>\n';
            });
            xml += '</jobs>';
            return xml;
        };

        const xml = convertToXML(jobs);
        const blob = new Blob([xml], { type: 'application/xml' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'jobs.xml';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    function seniorityConvert(value) {
        const mapping = {
            "null": '3',
            "Junior": '2',
            "Middle": '3',
            "Senior": '4',
            "Expert": '5'
        };
        
        // Handle the null value
        if (value === null) {
            return '3';
        }
        
        return mapping[value];
    }

    const fetchDataAndDownloadXML = async () => {
        try {
            const jobs = await getJobs([]);
            downloadXML(jobs);
        } catch (error) {
            console.error('Error downloading XML:', error);
        }
    };

    useEffect(() => {
        fetchDataAndDownloadXML();
    }, []);

    return <div>Downloading XML...</div>;
}