import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import "./JobAlertSubscribe.css";
import { getContractDurations, getRoles, getSeniorities, getWorkTypes, subscribe, getLocations } from "../../../api/sinapsi.services";

import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import Navigator from "../../navigator/Navigator";
import Multiselect from "multiselect-react-dropdown";

export default function JobAlertSubscribe() {
  const { t } = useTranslation();
  const [workTypes, setWorkTypes] = useState();
  const [seniorities, setSeniorities] = useState();
  const [contractDurations, setContractDurations] = useState();
  const [roles, setRoles] = useState();
  const [locations, setLocations] = useState();
  const [applicationDetails, setApplicationDetails] = useState({
    workType: [],
    seniority: [],
    contractDuration: [],
    role: [],
    email: "",
    user_captcha_input: "",
    GDPR: "Off",
    location: []
  });
  const [state, setState] = useState({
    validated: false,
    submitting: false,
    submitted: false,
    showModal: false,
    invalidCaptcha: false,
  });

  const links = [
    { link: "/jobs", label: "jobListing" },
    { link: "/job-alert/subscribe", label: "jobAlertSubscribe" },
  ];

  useEffect(() => {
    loadCaptchaEnginge(6, "#1da148", "#FFFFFF");
    const req1 = getWorkTypes().then((res) => {
      setWorkTypes(res);
    });
    const req2 = getContractDurations().then((res) => {
      setContractDurations(res);
    });
    const req3 = getSeniorities().then((res) => {
      setSeniorities(applyToNamesInArray(res));
    });
    const req4 = getRoles().then((res) => {
      setRoles(res);
    });
    const req5 = getLocations().then((res) => {
      const countries = Object.keys(res).map((countryName) => {
        return {
          name: countryName,
          type: "Countries"
        }
      })

      const cities = Object.values(res).flat().map((cityName) => {
        return {
          name: cityName,
          type: "Cities"
        }
      })

      setLocations(countries.concat(cities));
    });
  }, []);

  function onInputChanged(input) {
    if(input.target.id === "gdpr"){
      setApplicationDetails({
        ...applicationDetails,
        GDPR: applicationDetails.GDPR === "Off" ? "Extended" : "Off",
      });
    }
    else if (input.target.type === "checkbox"){
      if (applicationDetails[input.target.id] === undefined){
        setApplicationDetails({
          ...applicationDetails,
          [input.target.id]: true,
        })
      }
      else {
        setApplicationDetails({
          ...applicationDetails,
          [input.target.id]: !applicationDetails[input.target.id]
        });
      }
    } else {
      setApplicationDetails({
        ...applicationDetails,
        [input.target.id]: input.target.value,
      });
    }
  }

  const areMultiselectsInvalid = () => {
    return !applicationDetails.workType.length === 0 || applicationDetails.seniority.length === 0 || applicationDetails.contractDuration.length === 0 ||
      applicationDetails.role.length === 0;
  }

  function removeTrailingDigits(str) {
    return str.replace(/\d+$/, '');
  }
  const applyToNamesInArray = (arr) => {
    return arr.map((obj) => ({
      ...obj,
      name: removeTrailingDigits(obj.name),
    }));
  };

  function handleClose() {
    setState({ ...state, showModal: false });
  }

  function submitApplication(event) {
    const validCaptcha = validateCaptcha(
      applicationDetails.user_captcha_input,
      false
    );
    const form = document.getElementById("applicationForm");
    triggerShowValidation();
    if (!validCaptcha) {
      document.getElementById("user_captcha_input").value = "";
    }
    if (form.checkValidity() === false || !validCaptcha || areMultiselectsInvalid()) {
      setState({
        ...state,
        submitting: false,
        validated: true,
        invalidCaptcha: !validCaptcha,
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      setState({ ...state, submitting: true });
      const result = subscribe({
        WorkTypes: applicationDetails.workType,
        ContractDurations: applicationDetails.contractDuration,
        Seniorities: applicationDetails.seniority,
        Positions: applicationDetails.role,
        Email: applicationDetails.email,
        GDPR: applicationDetails.GDPR,
        Cities: applicationDetails.location.filter(element => element.type === 'Cities').map(element => element.name),
        Countries: applicationDetails.location.filter(element => element.type === 'Countries').map(element => element.name)
      }).then(() => {
          setState({ ...state, submitting: false, submitted: true });
        })
        .catch((ex) => {
          setState({ ...state, showModal: true });
        });
    }
  }

  function validateRole(selectedList) {
    if (selectedList.length === 0) {
      setInvalid('role');
    }
    else {
      setValid('role');
    }
  }

  function validateSeniority(selectedList) {
    if (selectedList.length === 0) {
      setInvalid('seniority');
    }
    else {
      setValid('seniority');
    }
  }

  function validateContractDuration(selectedList) {
    if (selectedList.length === 0) {
      setInvalid('contractDuration');
    }
    else {
      setValid('contractDuration');
    }
  }

  function validateWorkType(selectedList) {
    if (selectedList.length === 0) {
      setInvalid('workType');
    }
    else {
      setValid('workType');
    }
  }

  const setValid = (elementId) => {
    const element = document.getElementById(elementId);

    if (element.classList.contains("multiselectInvalid")) {
        element.classList.remove("multiselectInvalid");
    }
    element.classList.add("multiselectValid");
  }
  const setInvalid = (elementId) => {
    const element = document.getElementById(elementId);

    if (element.classList.contains("multiselectValid")) {
        element.classList.remove("multiselectValid");
    }
    element.classList.add("multiselectInvalid");
  }
  const triggerShowValidation = () => {
    let elements = document.querySelectorAll('.multiselect-container');
    elements.forEach(element => {
      element.classList.add('showValidation');
    });
    validateWorkType(applicationDetails.workType);
    validateContractDuration(applicationDetails.contractDuration);
    validateSeniority(applicationDetails.seniority);
    validateRole(applicationDetails.role);
  }

  const onChangeWorkType = (selectedList, selectedItem) => {
    validateWorkType(selectedList);

    setApplicationDetails ({
      ...applicationDetails,
      workType: selectedList.map(item => item.id)
    })
  }
  const onChangeContractDuration = (selectedList, selectedItem) => {
    validateContractDuration(selectedList);

    setApplicationDetails ({
      ...applicationDetails,
      contractDuration: selectedList.map(item => item.id)
    })
  }
  const onChangeSeniority = (selectedList, selectedItem) => {
    validateSeniority(selectedList);

    setApplicationDetails ({
      ...applicationDetails,
      seniority: selectedList.map(item => item.id)
    })
  }
  const onChangeRole = (selectedList, selectedItem) => {
    validateRole(selectedList);
    setApplicationDetails ({
      ...applicationDetails,
      role: selectedList.map(item => item.id)
    })
  }
  const onChangeLocation = (selectedList, selectedItem) => {
    setApplicationDetails ({
      ...applicationDetails,
      location: selectedList
    })
  }

  if (state.submitted && !state.showModal) {
    return (
      <div className="text-center mt-4">
        <FontAwesomeIcon
          className="display-3"
          icon={faCheckCircle}
        ></FontAwesomeIcon>
        <h2 className="mt-3">Subscribed to Alerts</h2>
        <p>You have successfully subscribed to job alerts.</p>
      </div>
    );
  }

  return (
    <div>
      <Header
        isJobHeader={true}
        jobTitle={"Subscribe to Job Alerts"}
        isJobApplication={false}
      ></Header>
      <Container className="mt-4">
        <Navigator links={links}></Navigator>
        <Modal show={state.showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Could not subscribe</Modal.Title>
          </Modal.Header>
          <Modal.Body>There was an issue while applying to job alerts. Please try again.</Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Form
          id="applicationForm"
          validated={state.validated}
          onSubmit={submitApplication}
          className="mt-4"
        >
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="workType">
                <Form.Label>{t("workType")} *</Form.Label>
                <Multiselect id="workType"
                  disable={state.submitting}
                  options = {workTypes}
                  displayValue="name"
                  placeholder={`Select ${t("workType")}`}
                  closeIcon="cancel"
                  onSelect={onChangeWorkType}
                  onRemove={onChangeWorkType}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="seniority">
                <Form.Label>{t("seniority")} *</Form.Label>
                <Multiselect id="seniority"
                  disable={state.submitting}
                  options = {seniorities}
                  displayValue="name"
                  placeholder={`Select ${t("seniority")}`}
                  closeIcon="cancel"
                  onSelect={onChangeSeniority}
                  onRemove={onChangeSeniority}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="contractDuration">
                <Form.Label>{t("contractDuration")} *</Form.Label>
                <Multiselect id="contractDuration"
                  disable={state.submitting}
                  options = {contractDurations}
                  displayValue="name"
                  placeholder={`Select ${t("contractDuration")}`}
                  closeIcon="cancel"
                  onSelect={onChangeContractDuration}
                  onRemove={onChangeContractDuration}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="role">
                <Form.Label>Roles *</Form.Label>
                <Multiselect id="role"
                  disable={state.submitting}
                  displayValue="name"
                  groupBy="domainName"
                  placeholder="Select Roles"
                  onSelect={onChangeRole}
                  onRemove={onChangeRole}
                  options={roles}
                  closeIcon="cancel"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="location">
                <Form.Label>Locations</Form.Label>
                <Multiselect id="location"
                  disable={state.submitting}
                  displayValue="name"
                  groupBy="type"
                  placeholder="Select Locations"
                  onSelect={onChangeLocation}
                  onRemove={onChangeLocation}
                  options={locations}
                  closeIcon="cancel"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>{t("email")} *</Form.Label>
                <Form.Control
                  disabled={state.submitting}
                  required={true}
                  onChange={onInputChanged}
                  type="email"
                  placeholder="name@example.com"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="captcha">
                <Form.Label>{t("captcha")} *</Form.Label>
                <LoadCanvasTemplate
                  reloadText={t("reloadCaptcha")}
                  reloadColor="#2980b9"
                />
                <Form.Control
                  required
                  className="mt-1"
                  disabled={state.submitting}
                  onChange={onInputChanged}
                  id="user_captcha_input"
                  name="user_captcha_input"
                  type="text"
                  isInvalid={state.invalidCaptcha}
                  placeholder={t("enterCaptcha")}
                />
              </Form.Group>
            </Col>
          </Row>
          <h3 className="mt-4">{t("gdprConsentTitle")}</h3>
          <Form.Group className="mb-3" controlId="gdpr">
            <Form.Label className="d-block">
              <Form.Check.Input
                disabled={state.submitting}
                type={"checkbox"}
                required={true}
                onChange={onInputChanged}
              />
              {t("jobAlertConsent")}
              <a
                target="_blank"
                href="https://www.rinf.tech/terms-conditions/recruitment-privacy-notice/"
                className="nohover d-inline-block" rel="noreferrer"
              >
                &nbsp;
                {t("privacyNoticeJobAlert")}
                &nbsp;
              </a>
            </Form.Label>
          </Form.Group>
        </Form>
        <div className="mt-5 mb-2 text-center">
          <button
            onClick={submitApplication}
            className="mx-2"
            variant="success"
          >
            Create Alert
          </button>
          <Link className="nohover d-inline-block" to={`/jobs`}>
            <button className="third mx-2">{t("cancel")}</button>
          </Link>
        </div>
      </Container>
      <Footer showJobs={true}></Footer>
    </div>
  );
}
