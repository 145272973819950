import "./Offline.css";
import optimize from "../../assets/optimize.png";
import { useEffect, useState } from "react";

const loadConfig = async () => {
    const response = await fetch('/config.json');
    const config = await response.json();
    return config;
};

const getMonthName = (monthIndex) => {
  const monthNames = [
    "January", "February", "March", "April", "May", "June", "July", "August", 
    "September", "October", "November", "December"
  ];
  return monthNames[monthIndex];
};

export default function Offline() {
    const [unavailableUntil, setUnavailableUntil] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        calculateUnavailable();
        setLoading(false);
    })

    const calculateUnavailable = async () => {
        const configData = (await loadConfig()).unavailableUntil;

        if (configData === "" || configData === null || configData === undefined) {
            setUnavailableUntil("");
        } else {
            const [day, month, year, hour = "00", minute = "00"] = configData.split(/[.\s:]/); // Split by '-' (day-month-year) and ':' (hour-minute)
            const hardcodedDate = new Date(`${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${minute.padStart(2, '0')}:00+02:00`); // Convert to ISO format with Bucharest offset

            const getBucharestTime = () => {
                const options = { timeZone: 'Europe/Bucharest', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
                return new Intl.DateTimeFormat([], options).format(new Date());
            };

            const currentBucharestTime = new Date(getBucharestTime()); // Current time in Bucharest

            if (currentBucharestTime.getDay() === hardcodedDate.getDay() &&
                currentBucharestTime.getMonth() === hardcodedDate.getMonth() &&
                currentBucharestTime.getDay() === hardcodedDate.getDay() && 
                configData.length <= 10) 
                setUnavailableUntil("today");
            else if (currentBucharestTime >= hardcodedDate) 
                    setUnavailableUntil("");
            else if (configData.length <= 10)
                setUnavailableUntil(configData);
            else 
                setUnavailableUntil(`${hour}:${minute} ${day}.${month}.${year}`);
        }
    }

    if (loading) return <div>Loading...</div>;

    else return (
        <div className="offlineContainer">

            <h3 className="maintenanceTitle">We'll Be Back Soon!</h3>

            <img src={optimize} className="maintenanceImage" alt="Maintenance"/>

            <div className="maintenanceParagraph">
                <p>
                    Apologies for the inconvenience, but our platform is currently undergoing maintenance and will be back online
                    {unavailableUntil === "" ? (
                    <> soon and</>
                    ) : (
                        <> by <span className="fw-bold">{unavailableUntil}</span> when</>
                    )} you will again be able to browse and apply to our available job openings.
                    <br />
                    <br />
                    If you have any questions in the meantime, don't hesitate to contact us via <a className="d-inline-block">
                        talent-acquisition@rinftech.com
                    </a>.
                    You can also send us your application via the same email mentioning the opportunity you are interested in the subject.
                </p>
            </div>
        </div>
    )
}